import { stringify } from 'query-string'
import API from '@axios'

const API_URL = '/api/expanded_entity'

export default {
  getFields({ schemaKey, tableKey }) {
    return API.get(`${API_URL}/${schemaKey}/${tableKey}/fields`)
  },

  create({ schemaKey, tableKey }, payload) {
    return API.post(`${API_URL}/${schemaKey}/${tableKey}/widget`, payload)
  },

  getAll({ schemaKey, tableKey }) {
    return API.get(`${API_URL}/${schemaKey}/${tableKey}/widgets`)
  },

  getOne({ schemaKey, tableKey, uuid }) {
    return API.get(`${API_URL}/${schemaKey}/${tableKey}/widget/${uuid}`)
  },

  reorder({ schemaKey, tableKey }, payload) {
    return API.patch(`${API_URL}/${schemaKey}/${tableKey}/widgets/order`, payload)
  },

  update({ schemaKey, tableKey, uuid }, payload) {
    return API.patch(`${API_URL}/${schemaKey}/${tableKey}/widget/${uuid}`, payload)
  },

  delete(uuid) {
    return API.delete(`${API_URL}/widget/${uuid}`)
  },

  toggleVisibility(uuid, query = {}) {
    return API.patch(`${API_URL}/widget/${uuid}/visibility?${stringify(query)}`)
  },
}
