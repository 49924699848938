export default {
  methods: {
    /**
     * "param" can be an object that has a "required" key in it or
     * a boolean value that represents required value
     */
    isRequired(param, requiredKey = 'required') {
      return param[requiredKey] ?? param
    },

    requiredText(param, text = 'Required') {
      return this.isRequired(param) ? `*${this.$t(text)}` : null
    },
  },
}
