<template>

  <div v-if="field.fieldType === 'WIDGET' && field.fieldLabel === '*Widget'">
    <p>{{ 'Widget' }}</p>
    <component
            :is="findChartComponent(widgetData.type)"
            class="widget-component"
            :type="widgetData.type"
            :result="widgetData.result"
            :chart-data="widgetData"
            :widget-id="widgetData.uuidKey"
            :dashboard-id="widgetData.uuidKey"
            :for-preview="true"
            :dashboard-filter-values="widgetData.type === 'TABLE' && widgetData.filters"
          />
  </div>
  <div v-else>
    <label class="text-uppercase tw-block text-muted tw-text-2xs">
      {{ $t(field.fieldLabel) }}
    </label>
    <div
      class="tw-text-sm tw-font-semibold text-body entity-field-value"
      :class="[getValueTextClass(field), centered ? 'tw-text-lg' : 'tw-text-base']"
    >
      <template v-if="field.fieldType === CustomFieldTypes.MultiSelect">
        {{ advancedFieldValue(customFields[field.fieldKey]) }}
      </template>
      <template v-if="field.fieldType === CustomFieldTypes.Bool">
        <feather-icon
          size="17"
          :icon="booleanIcon[getFieldValue(field)]"
        />
      </template>
      <template v-else>
        <!-- {{ $t(getFieldValue(field) || 'N/A') }} -->
        <span v-html="$t(getFieldValue(field))" />
      </template>

      <prozess-copy-to-clipboard
        v-if="getFieldValue(field) && getFieldValue(field) !== 'N/A'"
        :id="`copy-entity-${parentId}-${field.fieldKey}`"
        :text="field.fieldType === CustomFieldTypes.MultiSelect ? advancedFieldValue(customFields[field.fieldKey]) : getFieldValue(field)"
      />
    </div>
  </div>
</template>

<script>
import { getCustomFieldText, advancedFieldValue } from '@/helpers/customFields'
import CustomFieldTypes from '@/constants/customFieldTypes'
import { Entity, Schema } from '@/constants/app'
import { markupToText } from '@/helpers/app'
import ProzessCopyToClipboard from '@/@core/components/ProzessCopyToClipboard.vue'
import WidgetDataSourceTable from '@/components/Widgets/WidgetDataSourceTable.vue'
import ProzessChart from '@/components/chart/ProzessChart.vue'
import KpiChart from '@/components/shared/KpiChart.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { dashboardService } from '@/services/dashboard'

export default {
  components: { ProzessCopyToClipboard, FeatherIcon, KpiChart, ProzessChart, WidgetDataSourceTable },
  props: {
    field: {
      type: Object,
      required: true,
    },

    standardFields: {
      type: Object,
      default: () => ({}),
    },

    customFields: {
      type: Object,
      default: () => ({}),
    },

    schemaKey: {
      type: String,
      required: true,
    },

    parentId: {
      type: String,
      required: true,
    },

    centered: Boolean,
  },
  mounted() {
    this.fetchData()
  },

  data() {
    return {
      CustomFieldTypes,
      widgetData: {},
      booleanIcon: {
        true: 'CheckCircleIcon',
        false: 'XCircleIcon',
      },
    }
  },

  methods: {
    async fetchData() {
      if (this.field.fieldType === 'WIDGET' && this.field.fieldLabel === '*Widget') {
        const widgetDataResponse = await this.$async(
            dashboardService('widget').getWidgetSpecificData(this.field.fieldKey, this.$route.name === 'company-view' ? 'company' : 'contact-view', this.$route.params.id),
          )
        this.widgetData = widgetDataResponse.response.data
        console.log('widgetDataResponse', widgetDataResponse)
      }
    },
    formatValue(field, value) {
      /**
       * if the field is the Note's note
       * convert note's markup format to text
       */
      if (this.schemaKey === Schema.NOTES && Entity.NOTE && field.fieldKey === 'note') {
        return markupToText(value)
      }

      return value
    },
    findChartComponent(data) {
      const type = data.toLowerCase()
      if (type === 'kpi') return 'kpi-chart'
      if (type === 'line' || type === 'bar' || type === 'pie' || type === 'tree') return 'prozess-chart'
      return 'widget-data-source-table'
    },
    getFieldValue(field) {
      let value = field.customField
        ? this.customFields[field.fieldKey]
        : this.standardFields[field.fieldKey] || this.standardFields[this.$case.camel(field.fieldKey)]

      // This is to check if the value is indeed null, and not just falsy
      if (value === null) {
        return 'N/A'
      }

      value =
        field.customField || this.schemaKey === Schema.CUSTOM
          ? getCustomFieldText(
              this.customFields,
              field.fieldType,
              field.fieldKey,
              v => this.$n(v),
              v => this.$d(v),
            )
          : this.formatValue(field, value)

      return value?.toString() ?? 'N/A'
    },

    getValueTextClass(field) {
      const textClass = {
        [CustomFieldTypes.Phone]: 'text-body',
        [CustomFieldTypes.Url]: 'text-secondary',
        [CustomFieldTypes.Email]: 'text-secondary',
        [CustomFieldTypes.Number]: 'text-body',
        [CustomFieldTypes.Percent]: 'text-body',
        [CustomFieldTypes.Query]: 'text-body',
      }

      return this.getFieldValue(field) && this.getFieldValue(field) !== 'N/A' ? textClass[field.fieldType] : 'text-body'
    },

    advancedFieldValue,
  },
}
</script>
<style lang="scss">
.entity-field-value {
  position: relative;
  &:hover {
    .copy-to-clipboard {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 0px;
    }
  }
}
</style>
