<template>
  <b-modal
    id="generate-contract-modal"
    v-model="open"
    hide-footer
    size="sm"
    centered
    :title="$t('Generate Document')"
    >
      <b-overlay
        :show="onLoad"
        spinner-variant="primary"
        rounded="sm"
        variant="transparent"
        style="flex: 1"
        class="d-flex flex-column"
      >
      <div>
        <div>
          <div>
            <p class="mb-0">{{ $t('Select Document Template') }}</p>
            <ProzessSelect
              v-model="selectedDocumentTemplate"
              :options="documentTemplateOptions"
              style="width: 100%"
              :clearable="false"
              />
          </div>
          <div style="margin-top: 12px;">
            <p class="mb-0">{{ $t('Document Name') }}</p>
            <prozess-input
              v-if="overrideName"
              v-model="documentName"
              :error="$hasError('documentName')"
              field="documentName"
              name="documentName"
              style="padding-top: 0px"
            />
            <prozess-input
              v-else
              v-model="selectedDocumentTemplate.documentName"
              disabled="true"
              style="padding-top: 0px"
            />
            <b-form-checkbox
              inline
              style="margin-top: -12px"
              v-model="overrideName"
              @change="handleOverrideCbChange"
            >
             {{ $t('Override Name')}}
            </b-form-checkbox>
          </div>
        </div>
        <div style="border-top: 1px solid gainsboro; margin-top: 12px;" v-if="subsidiaryList">
          <p class="mb-0" style="margin-top: 12px;">{{ $t('Subsidiary Company Select') }}</p>
            <div class="comp-opt" v-for="(company, i) in subsidiaryList" :key="`company-select-${i}`">
              <b-form-checkbox
                inline
                style="margin-top: -12px; cursor: pointer"
                v-model="company.selected"
              >
              {{ company.name }}
              </b-form-checkbox>
              <p style="margin: 0px">{{ }}</p>
            </div>
        </div>
        <div class='action-divs' style="justify-content: flex-end; display: flex; padding-top: 10px;padding-bottom: 10px; margin-top: 32px; border: 1px; border-top: 1px solid gainsboro">
            <b-button
               style="margin-right: 8px; "
              @click="closeModal">
              <span class="align-middle">{{ this.$t('Cancel') }}</span>
            </b-button>
            <b-button
            :id="`export-table-button`"
            :disabled="!this.selectedDocumentTemplate.key || hasNoDocuName()"
            @click="(!onLoad && generateAndSave())">
            <feather-icon icon="DownloadIcon" class="mr-50"/>
            <span class="align-middle">{{ this.$t('Generate and Save') }}</span>
          </b-button>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>

import ProzessSelect from '@/@core/components/ProzessSelect.vue'
import contractTemplateService from '@/services/contractTemplate'
import moment from 'moment'

export default {
  components: {
    ProzessSelect,
  },
  data() {
    return {
      open: false,
      loading: false,
      selectedDocumentTemplate: { label: '' },
      documentTemplateOptions: [],
      onLoad: false,
      generateAllRelation: false,
      documentName: '',
      overrideName: false,
      subsidiaryList: null,
    }
  },
  methods: {
    async openModal({ open, generateAllRelation }) {
      this.open = open
      this.generateAllRelation = generateAllRelation
      this.documentName = ''
      this.selectedDocumentTemplate = { label: '' }
      this.overrideName = false
      this.getTemplates()
    },
    async closeModal() {
      this.selectedDocumentTemplate = { label: '' }
      this.open = false
    },
    async handleOverrideCbChange(overrid) {
      if (overrid) {
        this.documentName = this.selectedDocumentTemplate.documentName
      }
    },
    async getTemplates() {
      this.onLoad = true
      const { response } = await this.$async(contractTemplateService.getAll())
      if (this.generateAllRelation) {
        const subRes = await this.$async(contractTemplateService.getSubsidiaries(this.$route.params.id))
        this.subsidiaryList = subRes.response.data.subsidiaryList
      }
      this.documentTemplateOptions =
        response.data.pageItems.map(t => ({
          key: t.id,
          label: t.name,
          documentName: `${t.name.replace(/\s+/g, '-').toLowerCase()}-${moment(new Date()).format('YYYY-MM-DD')}`,
        }))
      this.onLoad = false
    },
    hasNoDocuName() {
      const name = this.overrideName ? this.documentName.trim() : this.selectedDocumentTemplate.documentName
      return name == null || name === ''
    },
    async generateAndSave() {
      this.onLoad = true
      const generateDocumentRequest = {
        companyId: this.$route.params.id,
        templateId: this.selectedDocumentTemplate.key,
        generateAllRelation: this.generateAllRelation,
        documentName: this.overrideName ? this.documentName : this.selectedDocumentTemplate.documentName,
      }
      if (this.generateAllRelation) {
        const finalSubsidiaryList = this.subsidiaryList.filter(s => s.selected).map(s => s.uuid)
        generateDocumentRequest.subsidiaryList = finalSubsidiaryList
      }
      await this.$async(contractTemplateService.generate(generateDocumentRequest)).then(async res => {
          const docLinkReqRes = res.response.data
           await contractTemplateService.linkDocument(docLinkReqRes)
           this.closeModal()
           if (this.generateAllRelation) {
           this.$router.push({
                name: this.$route.name,
                params: {
                    id: this.$route.params.id,
                    idFeed: this.$route.params.idFeed,
                    tab: 'documents',
                },
            })
           } else {
            this.$router.push({})
           }
        }).finally(() => {
          this.onLoad = false
        })
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
.comp-opt {
  background-color:rgba(0, 0, 0, 0.03);
  padding: 4px 6px 4px 6px;
  margin-top: 4px;
  margin-left: 6px;
  // cursor:pointer;
}
</style>
