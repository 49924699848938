<template>
  <masonry
    :key="masonryKey"
    :cols="masonryColumns"
    :gutter="12"
  >
    <EntityOverviewWidget
      v-for="(widget, i) in visibleWidgets"
      :key="i"
      class="tw-mb-4"
      :widget="widget"
      :schema-key="schemaKey"
      :table-key="tableKey"
      :resource="resource"
    />
    <template v-if="!loading && !visibleWidgets.length">
      <EntityOverviewStandardFieldsWidget
        v-if="hasStandardFields"
        class="tw-mb-4"
        :standard-fields="resource"
        :field-labels="fieldLabels"
        :schema-key="schemaKey"
      />
      <EntityOverviewCustomFieldsWidget
        v-if="hasCustomFields"
        class="tw-mb-4"
        :custom-fields="resource.customFields"
        :schema-key="schemaKey"
        :title="$t(hasStandardFields ? 'More Information' : 'Basic Information')"
      />
    </template>
  </masonry>
</template>

<script>
import { GenericTabs } from '@/constants/app'
import expandedEntityOverviewService from '@/services/expandedEntityOverview'
import EntityOverviewWidget from '@/components/EntityOverview/EntityOverviewWidget.vue'
import EntityOverviewStandardFieldsWidget from '@/components/EntityOverview/EntityOverviewStandardFieldsWidget.vue'
import EntityOverviewCustomFieldsWidget from '@/components/EntityOverview/EntityOverviewCustomFieldsWidget.vue'

export default {
  components: {
    EntityOverviewCustomFieldsWidget,
    EntityOverviewStandardFieldsWidget,
    EntityOverviewWidget,
  },

  props: {
    schemaKey: {
      type: String,
      required: true,
    },

    tableKey: {
      type: String,
      required: true,
    },

    resource: {
      type: Object,
      required: true,
    },

    fieldLabels: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      widgets: [],
      masonryKey: 0,
    }
  },

  computed: {
    entity() {
      return { schemaKey: this.schemaKey, tableKey: this.tableKey }
    },

    hasCustomFields() {
      return !!Object.keys(this.resource.customFields).length
    },

    hasStandardFields() {
      return !!this.fieldLabels
    },

    masonryColumns() {
      let masonryColumns = 1

      if (this.mqExtraLarge) {
        masonryColumns = 3
      } else if (this.mqLarge) {
        masonryColumns = 2
      }

      return masonryColumns
    },

    visibleWidgets() {
      return this.widgets.filter(({ visible }) => visible)
    },
  },

  watch: {
    '$route.params': {
      handler(params) {
        if (params.tab === GenericTabs.OVERVIEW_360) {
          this.getWidgets()
        }
      },

      immediate: true,
    },

    masonryColumns: {
      handler() {
        // This is to force rerender the Masonry component when the masonryColumns value changed
        ++this.masonryKey
      },

      immediate: true,
    },
  },

  methods: {
    async getWidgets() {
      this.loading = true

      const { response } = await this.$async(expandedEntityOverviewService.getAll({ ...this.entity }))

      this.widgets = response.data

      this.loading = false
    },
  },
}
</script>
