import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper, getSchemaName } from '../helpers/apiHelper'

const API_URL = '/api/documents/global'

export default {
  ...apiHelper(API_URL),
  getDataFeed: (query = { page: 0 }) => API.get(`/api/data_feed/documents/document?${toQuery(query)}`),
  getCustomFields: () => API.get(`${API_URL}/custom_fields`),
  getHistory: (id, payload = { size: 25 }) => API.get(`${API_URL}/${id}/history?${toQuery(payload)}`),
  updateWithNewFile: (id, payload) => API.put(`${API_URL}/${id}/content`, payload),
  attach(id, payload) {
    payload.tableName = payload.entityType ?? payload.tableName
    payload.schemaName = getSchemaName(payload.entityType ?? payload.tableName)

    return API.put(`${API_URL}/${id}/attach_to`, payload)
  },
  detach(id, payload) {
    return API.put(`${API_URL}/${id}/detach_from`, payload)
  },
  attachedTo: id => API.get(`${API_URL}/${id}/attached_to`),
  autocomplete: (schemaName, tableName, search) => API.get(`${API_URL}/${schemaName}/${tableName}/autocomplete?query=${search}`),
  directUrl: (id, versionId) => {
    const query = {}
    if (versionId) {
      query.versionId = versionId
    }
    return API.get(`${API_URL}/${id}/direct_url?${toQuery(query)}`)
  },
}
