<template>
  <b-card class="p-1">
    <div class="tw-flex tw-items-center">
      <feather-icon
        class="text-secondary tw-mr-2 tw-shrink-0"
        icon="InfoIcon"
        size="18"
      />
      <p class="tw-inline-block m-0 line-clamp-1 tw-text-base tw-font-medium">
        {{ $t('Basic Information') }}
      </p>
    </div>
    <hr>
    <div class="py-2 px-2 px-xl-3">
      <EntityOverviewWidgetField
        v-for="(field, i) in fieldValues"
        :key="i"
        :parent-id="standardFields.uuid"
        class="tw-mb-6 text-white"
        :schema-key="schemaKey"
        :field="field"
        :standard-fields="standardFields"
        :centered="
          fieldValues.length === 1 &&
            field.fieldType !== CustomFieldTypes.LongText
        "
      />
    </div>
  </b-card>
</template>

<script>
import { getInfoText } from '@/helpers/customFields'
import EntityOverviewWidgetField from '@/components/EntityOverview/EntityOverviewWidgetField.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'

export default {
  components: {
    EntityOverviewWidgetField,
  },

  props: {
    schemaKey: {
      type: String,
      required: true,
    },

    standardFields: {
      type: Object,
      required: true,
    },

    fieldLabels: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      CustomFieldTypes,
    }
  },

  computed: {
    fieldValues() {
      return Object.keys(this.standardFields).reduce((fieldValues, key) => {
        if (this.fieldLabels[key]) {
          fieldValues.push(
            getInfoText(
              this.standardFields,
              this.fieldLabels,
              key,
              v => this.$d(v),
              this.$case,
            ),
          )
        }

        return fieldValues
      }, [])
    },
  },
}
</script>
