export function fetchBackendErrors(error, resourceId = null) {
  const fieldKey = error.response?.data[0]?.field
  const code = error.response?.data[0]?.code
  let message = error.response?.data[0]?.defaultMessage

  const longErrorMessage = error.response?.data?.message

  if (typeof (error.response.data) === 'string') {
    if (error.response.data.includes('exist') === true) return error.response.data
  }

  if (longErrorMessage && longErrorMessage.includes('past date') === false) {
    return 'Some fields are too long'
  }

  if (code === 'Size' || code?.includes(code)) {
    const maxValue = message.split(' ').pop()
    message = code === 'NotNull' ? 'is required' : `Max limit ${maxValue}`

    return `${fieldKey} field: ${message}`
  }
  return false
}
