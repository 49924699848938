import API from '@axios'
import { apiHelper, getSchemaName } from '@/helpers/apiHelper'
import { stringify } from 'query-string'

const API_URL = '/api/core/notes'

const attachOrDetach = (action, id, payload) => {
  const { noteVersion, entityType, entityId } = payload

  payload = {
    noteUuid: id,
    noteVersion,
  }

  return API.put(`${API_URL}/${getSchemaName(entityType)}/${entityType}/${entityId}/${action}`, payload)
}

const deleteByEntity = (schemaName, entityType, entityId, noteUuid, query) =>
  API.delete(`${API_URL}/${schemaName}/${entityType}/${entityId}/${noteUuid}?${stringify(query)}`)

export default {
  ...apiHelper(API_URL),

  getCustomFields() {
    return API.get(`${API_URL}/custom_fields`)
  },

  getHistory(id, payload = { size: 25 }) {
    return API.get(`${API_URL}/${id}/history?${stringify(payload)}`)
  },

  attachOrDetach,

  attach: (id, payload) => attachOrDetach('attach', id, payload),

  detach: (id, payload) => attachOrDetach('detach', id, payload),

  getAttached: (schemaName, entityType, entityId, query = { page: 0, size: 25 }) =>
    API.get(`${API_URL}/${schemaName}/${entityType}/${entityId}?${stringify(query)}`),

  detachOrDelete: (type, { schemaName, entityType, entityId, noteUuid, noteVersion }) => {
    if (type === 'detach') {
      return API.put(`${API_URL}/${schemaName}/${entityType}/${entityId}/detach`, {
        noteUuid,
        noteVersion,
      })
    }

    if (type === 'delete') {
      const query = { noteVersion }

      return deleteByEntity(schemaName, entityType, entityId, noteUuid, query)
    }

    return Promise.reject()
  },

  createByEntity: (schemaName, entityType, entityId, payload) => API.post(`${API_URL}/${schemaName}/${entityType}/${entityId}`, payload),

  getOneByEntity: (schemaName, entityType, entityId, noteUuid) => API.get(`${API_URL}/${schemaName}/${entityType}/${entityId}/${noteUuid}`),

  getAllDocumentsByEntity: (schemaName, entityType, entityId, noteUuid) => API.get(`${API_URL}/${schemaName}/${entityType}/${entityId}/${noteUuid}/documents`),

  getOneDocumentsByEntity: (schemaName, entityType, entityId, noteUuid, documentUuid) =>
    API.get(`${API_URL}/${schemaName}/${entityType}/${entityId}/${noteUuid}/documents/${documentUuid}`),

  updateByEntity: (schemaName, entityType, entityId, id, payload) => API.patch(`${API_URL}/${schemaName}/${entityType}/${entityId}/${id}`, payload),

  deleteByEntity,
}
