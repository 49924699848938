/**
 * Also, do not forget to provide values for:
 * • canDetachOrDelete
 * • handleDeletionCompletion
 * • checkIfDetachOnly
 * • detachOrDelete
 */
export default ({
  uuidKey = 'uuid',
  versionKey = 'version',
  detachOnlyNotice = 'This item is also linked to some other records and can only be detached.',
  detachOrDeleteNotice = 'Do you want to detach this item to this record only or to delete it completely?',
}) => ({
  methods: {
    async confirmRemoval(item) {
      if (!this.canDetachOrDelete) return

      this.$swal({ title: this.$t('Verifying...') })
      this.$swal.showLoading()

      const detachOnly = await this.checkIfDetachOnly(item)
      this.$swal.close()

      if (detachOnly) {
        const options = {
          title: this.$t('Warning'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }

        const html = `<h2>${this.$t('Are you sure you want to detach?')}</h2>`
        options.html = detachOnlyNotice ? `<p>${this.$t(detachOnlyNotice)}</p>${html}` : html

        this.$swal(options).then(({ value }) => {
          if (value) {
            this.remove('detach', item)
          }
        })
      } else {
        this.showDeleteOrDetachModal(item)
      }
    },

    async remove(action, item) {
      const version = this.getItemVersion ? await this.getItemVersion(item) : item[versionKey]
      await this.$async(
        this.detachOrDelete(action, {
          schemaName: this.schemaName,
          entityType: this.entityType,
          entityId: this.entityId,
          [uuidKey || 'uuid']: item.uuid,
          [versionKey || 'version']: version,
        }),
      )

      this.handleDeletionCompletion()
    },

    showDeleteOrDetachModal(item) {
      this.$swal({
        title: this.$t(detachOrDeleteNotice),
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: this.$t('Delete'),
        confirmButtonText: this.$t('Detach'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-secondary',
          denyButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const { isConfirmed: isDetach, isDenied: isDelete } = result

        const action = (() => {
          if (isDetach) return 'detach'
          if (isDelete) return 'delete'
          return false
        })()

        if (action) {
          this.remove(action, item)
        }
      })
    },
  },
})
