<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-input
        v-model="form.title"
        :placeholder="$t('Title')"
        icon="TypeIcon"
        field="title"
        name="title"
        :error="$hasError('title')"
        @enter="save"
      />
      <prozess-quill-editor
        v-model="form.note"
        class="mb-2"
        :placeholder="$t('Note')"
        :error="$hasError('note')"
      />

      <!-- Custom Fields -->
      <!-- <template v-if="customFields.length">
        <h4 class="mb-2">
          {{ $t('Custom Fields') }}
        </h4>
        <div>
          <prozess-custom-fields
            v-model="resource.customFields"
            :custom-fields="customFields"
            :errors="errors"
            @enter="save"
          />
        </div>
      </template>
      -->

      <div v-for="field in filteredDuplicatedField" :key="field.key">
        <AppDynamicField
          v-model="form[field.key]"
          :field="field"
          :error="$hasError(field.key)"
          :resourceId="resourceId"
        />
      </div>
      <template v-if="entityType">
        <prozess-file-upload
          v-if="!resourceId"
          v-model="file"
          :max-file-size="10"
          :allowed-mime-types="allowedMimeTypes"
        />
      </template>

      <form-notification v-if="resourceId" :id="resourceId" />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import FormNotification from '@/components/Forms/FormNotification.vue'
import { noteSchema } from '@/schema/note'
import noteService from '@/services/note'
import advancedFieldMgmt from '@/services/advancedFieldMgmt'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import fieldMixins, { customFieldsMixins } from '@/mixins/fields'
import { fillableOnly } from '@/helpers/field'
import { markupToText } from '@/helpers/app'
import { fetchBackendErrors } from '@/helpers/backendValidations'
import ProzessQuillEditor from '@core/components/ProzessQuillEditor.vue'
import moment from 'moment'

const staticField = {
  title: 'title',
  note: 'note',
}
export default {
  name: 'NotesForm',
  components: {
    FormNotification,
    ProzessQuillEditor,
    AppDynamicField,
  },
  mixins: [fieldMixins, customFieldsMixins],

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },

    entityType: {
      type: String,
      default: null,
    },

    entityId: {
      type: [Number, String],
      default: null,
    },

    schemaName: {
      type: String,
      default: 'crm',
    },
  },

  data() {
    return {
      formId: 'noteForm',
      fieldMetadata: [],
      form: {},
      saving: false,
      loading: true,
      errors: [],
      version: '',
      customFields: [],
      allowedMimeTypes: [],
      filteredDuplicatedField: [],
      serviceName: noteService,
      file: null,
      fileName: '',
    }
  },

  computed: {
    formTitle() {
      return this.resourceId ? 'Edit Note' : 'Add New Note'
    },

    canDelete() {
      return this.$can('Update', 'CRM')
    },
  },

  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          this.getInitialData()
        }
      },

      immediate: true,
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    createOrUpdate(data) {
      if (this.entityType) {
        return this.resourceId
          ? noteService.updateByEntity(
            this.schemaName,
            this.entityType,
            this.entityId,
            this.resourceId,
            data,
          )
          : noteService.createByEntity(
            this.schemaName,
            this.entityType,
            this.entityId,
            this.preparePayload(data),
          )
      }

      return this.resourceId
        ? noteService.patch(this.resourceId, data)
        : noteService.store(data)
    },

    async getInitialData() {
      this.loading = true

      // if (this.resourceId) {
      //   await this.$async(this.getOne(this.resourceId))
      // } else {
      //   const { response } = await this.$async(noteService.getCustomFields())
      //   this.customFields = this.$customFields.sort(response.data)
      // }

      const { response } = await this.$async(
        advancedFieldMgmt.getFieldsByEntity({
          schema: 'notes',
          table: 'note',
        }),
      )

      const fieldMetadata = fillableOnly(response.data)

      this.filteredDuplicatedField = fieldMetadata.filter(
        fieldFilter => !Object.values(staticField).some(val => val === fieldFilter.key),
      )
      fieldMetadata.forEach(field => {
        if (field.type === 'BOOL') {
          this.$set(this.form, field.key, false)
        } else this.$set(this.form, field.key, null)
      })

      this.fieldMetadata = this.$lodash.cloneDeep(fieldMetadata)
      if (this.resourceId) {
        await this.fetchData()
      }
      this.loading = false
    },

    async fetchData() {
      const { response } = await this.$async(
        this.serviceName.getOne(this.resourceId),
      )
      this.version = response.data.noteVersion
      this.formatAsFormData(response.data)
    },
    async getOne(id) {
      const request = this.entityType
        ? noteService.getOneByEntity(
          this.schemaName,
          this.entityType,
          this.entityId,
          id,
        )
        : noteService.getOne(id)

      const { response } = await this.$async(request)
      const { data } = response

      this.customFields = this.$customFields.sort(
        data.customFieldMetadataSingleResponses,
      )

      this.resource = {
        ...data,
        note: data.notes,
        customFields: this.$customFields.formatForResource(
          data.customFieldMetadataSingleResponses,
          data.customFieldDataResponses,
        ),
      }

      this.fileName = this.resource.fileName
      delete this.resource.fileName
    },

    handleNewFileInputChange(e) {
      const maxFileSize = 10
      const [file] = e.target.files

      const validations = [
        {
          condition: maxFileSize < file.size / 1024 / 1024,
          message: `${this.$t('File should not exceed')} ${maxFileSize}mb`,
        },
        {
          condition:
            this.allowedMimeTypes.length
            && !this.allowedMimeTypes.includes(file.type),
          message: this.$t('File type not supported'),
        },
      ]

      let containsError = false

      validations.forEach(item => {
        if (containsError) return

        if (item.condition) {
          containsError = true
          this.$refs.newFileInput.value = ''

          this.currentValue = null
          return this.$swal({
            title: item.message,
            icon: 'warning',
            confirmButtonText: this.$t('Close'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
            allowOutsideClick: false,
          })
        }
      })

      if (containsError) return

      this.fileName = file.name
      this.file = file
    },

    replaceFile() {
      this.$refs.newFileInput.click()
    },

    reset() {
      this.errors = []
      this.form = {}
      this.customFields = []
      this.loading = false
      this.saving = false
      this.file = null
    },

    preparePayload(data) {
      const formName = 'notesForm'
      const formData = new FormData()
      formData.append(
        formName,
        new Blob([JSON.stringify(data)], {
          type: 'application/json',
        }),
      )

      if (this.file) {
        formData.append(
          'form',
          new Blob([JSON.stringify({ name: this.file.name })], {
            type: 'application/json',
          }),
        )

        formData.append('file', this.file)
      }

      return formData
    },

    async save() {
      const staticFieldValidations = [
        {
          required: true,
          key: 'title',
          type: 'TEXT',
        },
        {
          required: true,
          key: 'note',
          type: 'LONGTEXT',
        },
      ]
      this.errors = await this.yupValidate(
        noteSchema([...this.fieldMetadata, ...staticFieldValidations]),
        { ...this.form, note: markupToText(this.form.note)?.trim() },
      )
      this.saving = false
      if (!this.errors.length) {
        this.saving = true
        const payload = {
          noteVersion: this.resourceId ? this.version : undefined,
          note: this.form.note,
          ...this.formatAsPayload(),
        }
        Object.values(this.fieldMetadata).filter(cf => cf.dateAutoset && cf.customField).forEach(cc => {
          const dateNow = new Date()
          if (cc.type === 'DATE') {
            payload.customFields[cc.key] = moment(dateNow).format('YYYY-MM-DD')
          } else if (cc.type === 'DATETIME') {
            payload.customFields[cc.key] = moment(dateNow).format('YYYY-MM-DDTHH:mmZ')
          }
        })

        const response = await this.$async(this.createOrUpdate(payload))
        if (response.errors || response.error) {
          this.saving = false
          const isMaxLength = fetchBackendErrors(response.error, this.resourceId)

          return this.showWarning(
            !isMaxLength
              ? this.$t(
                "Oops! Something went wrong on our end. We'll fix this as soon as possible.",
              )
              : isMaxLength,
          )
        }
        // delete this.resource.mimeType
        this.$emit('saved')
        this.close()

        // const { errors } = await this.$async(
        //   this.createOrUpdate(this.resource),
        // )
        // if (errors) {
        //   this.errors = this.$loadErrors(errors)
        // } else {
        //   this.$emit('saved')
        //   this.close()
        // }

        this.saving = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
