import * as yup from 'yup'

import CustomFieldTypes, {
  customFieldValidations,
} from '@/constants/customFieldTypes'

export const customFieldsSchema = customFields => yup.object().shape({
  ...customFields.reduce((acc, customField) => {
    let validation = customFieldValidations(yup)[
      customField.fieldType
    ]

    if (validation) {
      if (customField.required) {
        const fieldKey = customField.fieldType === CustomFieldTypes.Percent
          ? `___${customField.key}`
          : customField.key

        validation = validation({ required: true })
        acc[fieldKey] = validation.type === 'lazy' ? validation : validation.required()
      } else {
        acc[customField.key] = validation()
      }
    }
    return acc
  }, {}),
})

export default {
  customFieldsSchema,
}
