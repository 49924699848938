<template>
  <b-card>
    <div class="tw-flex tw-items-center">
      <feather-icon
        class="text-secondary tw-mr-2 tw-shrink-0"
        icon="InfoIcon"
        size="16"
      />
      <p class="tw-inline-block m-0 line-clamp-1 tw-text-sm tw-font-medium text-white">
        {{ title }}
      </p>
    </div>
    <hr>
    <EntityOverviewWidgetField
      v-for="(field, i) in fieldValues"
      :key="i"
      class="tw-mb-6 text-white"
      :parent-id="$route.params.id"
      :schema-key="schemaKey"
      :field="field"
      :custom-fields="customFields"
      :centered="
        fieldValues.length === 1 &&
          field.fieldType !== CustomFieldTypes.LongText
      "
    />
  </b-card>
</template>

<script>
import { getInfoText } from '@/helpers/customFields'
import EntityOverviewWidgetField from '@/components/EntityOverview/EntityOverviewWidgetField.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'

export default {
  name: 'EntityOverviewCustom',
  components: {
    EntityOverviewWidgetField,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    schemaKey: {
      type: String,
      required: true,
    },

    customFields: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      CustomFieldTypes,
    }
  },

  computed: {
    fieldLabels() {
      return Object.keys(this.customFields).reduce((fieldLabels, key) => {
        fieldLabels[key] = this.$case.title(key)
        return fieldLabels
      }, {})
    },

    fieldValues() {
      return Object.keys(this.customFields).reduce((fieldValues, key) => {
        if (this.fieldLabels[key]) {
          const field = getInfoText(
            this.customFields,
            this.fieldLabels,
            key,
            v => this.$d(v),
            this.$case,
          )

          fieldValues.push({ ...field, customField: true })
        }

        return fieldValues
      }, [])
    },
  },
}
</script>
