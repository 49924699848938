<template>
  <div>
    <prozess-input
      v-if="isTypeNumeric"
      v-model.number="nativeValue"
      type="number"
      :bind="{ ...$attrs }"
      :disabled="$attrs.disabled"
      :placeholder="$attrs.placeholder || $t($case.title(field.label))"
      :icon="customFieldTypeIcons[field.type]"
      :error="error"
      :hint="hint || requiredText(field)"
      @enter="$emit('enter')"
      @wheel="$event.target.blur()"
    />
    <div v-else-if="field.type === CustomFieldTypes.Bool" class="my-1">
      <b-form-checkbox
        v-model="nativeValue"
        switch
        :disabled="$attrs.disabled"
        inline
        @keyup.enter.native="$emit('enter')"
      >
        <span style="font-size: 1rem">{{ $t($case.title(field.label)) }}</span>
      </b-form-checkbox>
      <span v-if="error" class="invalid-feedback">
        {{ error }}
      </span>
    </div>
    <prozess-field-wrapper
      v-else-if="field.type === CustomFieldTypes.Date && !isFieldDateTime(field.key)"
      :error="error"
      :hint="hint || requiredText(field)"
    >
      <label>{{ $t(field.label) }}</label>
      <label v-if="this.field.dateAutoset && !this.resourceId">{{ $t(' (Auto-set)') }}</label>
      <prozess-date-picker
        v-model="nativeValue"
        :is-birth-date="(['birth_date', 'start_date', 'startdatos'].includes(field.key))"
        :disabled="$attrs.disabled || (this.field.readOnly && this.resourceId) || (this.field.dateAutoset && !this.resourceId)"
        :locale="$i18n.locale"
        :placeholder="$attrs.placeholder || ' '"
        @keyup.enter.native="$emit('enter')"
      />
    </prozess-field-wrapper>

    <prozess-field-wrapper
      v-else-if="isFieldDateTime(field.key) || field.type === CustomFieldTypes.DateTime"
      :error="error"
      :hint="hint || requiredText(field)"
    >
      <label>{{ $t(field.label) }}</label>
      <label v-if="this.field.dateAutoset && !this.resourceId">{{ $t(' (Auto-set)') }}</label>
      <div class="tw-flex tw-w-full">
        <b-form-datepicker
          v-model="dateTimeControl.date"
          :date-format-options="{ month: 'short', weekday: 'short' }"
          :locale="$i18n.locale"
          class="tw-w-7/12"
          reset-button
          @keyup.enter.native="$emit('enter')"
          :disabled="(this.field.readOnly && this.resourceId) || (this.field.dateAutoset && !this.resourceId)"
        />
        <b-form-timepicker
          v-model="dateTimeControl.time"
          placeholder="Time"
          class="tw-w-5/12"
          reset-button
          :disabled="(this.field.readOnly && this.resourceId) || (this.field.dateAutoset && !this.resourceId)"
        />
      </div>
    </prozess-field-wrapper>

    <prozess-field-wrapper
      v-else-if="field.type === CustomFieldTypes.LongText"
      :error="error"
      :hint="hint || requiredText(field)"
      class="tw-flex-1 tw-pt-3"
    >
      <prozess-textarea
        v-model="nativeValue"
        :disabled="$attrs.disabled"
        :placeholder="$attrs.placeholder || $t($case.title(field.label))"
        :rows="$attrs.rows || 3"
      />
    </prozess-field-wrapper>
    <prozess-field-wrapper
      v-else-if="isTypeListLike"
      :icon="customFieldTypeIcons[field.type]"
      :error="error"
      :hint="hint || requiredText(field)"
      :disabled="$attrs.disabled"
      class="tw-flex-1 tw-pt-3"
    >
      <prozess-select
        v-model="nativeValue"
        :disabled="$attrs.disabled"
        class="tw-flex-1"
        :multiple="isMultiSelect"
        :placeholder="$t($case.title(field.label))"
        :options="field.values"
        :label="$t($case.title(field.label))"
        @keyup.enter.native="$emit('enter')"
      />
    </prozess-field-wrapper>
    <prozess-input
      v-else
      v-model="nativeValue"
      :bind="{ ...$attrs }"
      :disabled="$attrs.disabled"
      :placeholder="$attrs.placeholder || $t($case.title(field.label))"
      :icon="customFieldTypeIcons[field.type]"
      :error="error"
      :hint="hint || requiredText(field)"
      @enter="$emit('enter')"
    />
  </div>
</template>

<script>
import CustomFieldTypes, {
  customFieldTypeIcons,
} from '@/constants/customFieldTypes'
import requiredValidatorMixin from '@/mixins/requiredValidator'
import { customFieldsMixins } from '@/mixins/fields'
import dayjs from 'dayjs'

export default {
  mixins: [requiredValidatorMixin, customFieldsMixins],

  props: {
    error: {
      type: String,
      default: null,
    },

    field: {
      type: Object,
      default: null,
    },

    hint: {
      type: String,
      default: null,
    },

    value: {
      type: [String, Number, Date, Boolean, Array, Object],
      default: null,
    },

    resourceId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      customFieldTypeIcons,
      nativeValue: '',
      CustomFieldTypes,
      dateTimeControl: {
        date: null,
        time: null,
      },
    }
  },

  computed: {
    isTypeNumeric() {
      return [CustomFieldTypes.Number, CustomFieldTypes.Percent].includes(
        this.field.type,
      )
    },

    isTypeListLike() {
      return [CustomFieldTypes.List, CustomFieldTypes.MultiSelect].includes(
        this.field.type,
      )
    },

    isMultiSelect() {
      return this.field.type === CustomFieldTypes.MultiSelect
    },

    isPercent() {
      return this.field.type === CustomFieldTypes.Percent
    },

    isEmail() {
      return this.field.type === CustomFieldTypes.Email
    },

    isBool() {
      return this.field.type === CustomFieldTypes.Bool
    },

    isURL() {
      return this.field.type === CustomFieldTypes.Url
    },

    isDate() {
      return this.field.type === CustomFieldTypes.Date
    },
  },

  watch: {
    value: {
      handler(value) {
        if (Boolean(value) && ((this.isDate && this.isFieldDateTime(this.field.key))
               || this.field.type === CustomFieldTypes.DateTime)) {
          this.dateTimeControl.date = dayjs(value).format('YYYY-MM-DD')
          this.dateTimeControl.time = dayjs(value).format('HH:mm')
        } else {
          this.nativeValue = this.isPercent
            ? this.$round10(+value * 100, -4)
            : value
        }
      },
      immediate: true,
    },

    dateTimeControl: {
      deep: true,
      handler(value) {
        this.nativeValue = dayjs(`${value.date} ${value.time}`).format('YYYY-MM-DDTHH:mmZ')
      },
    },

    nativeValue: {
      handler(value) {
        let data = this.isPercent ? value / 100 : value

        if (this.isTypeNumeric) {
          data = !data && data !== 0 ? null : data
        } else if (this.isEmail || this.isURL) {
          data = data || null
        } else if (this.isMultiSelect) {
          data = data && data.length ? data : null
        }

        if ((this.isDate && this.isFieldDateTime(this.field.key))
               || this.field.type === CustomFieldTypes.DateTime) {
          if (!this.dateTimeControl.time) {
            this.error = this.$t('Receive date time is required')
          }
        }
        this.$emit('input', data)
      },
    },
  },
}
</script>
