<template>
  <div>
    <connection-form
      :version-key="versionKey"
      :service="service"
      :version="resource[versionKey]"
      :visible="showForm"
      :resource-id="resourceId"
      :company-id="companyId"
      @saved="$emit('update')"
      @close="onCloseForm"
    />
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="height: 100%;"
      class="d-flex flex-column"
    >
      <div class="card resource-view__scrollable">
        <div class="card-body">
          <div class="mb-2">
            <button
              id="create-button"
              class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
              :class="{
                'icon-disabled': canUpdate === false,
              }"
              @click="canUpdate && create()"
            >
              <feather-icon
                icon="UserPlusIcon"
                class="mr-50"
              />
              <span>{{ $t('New Connection') }}</span>
            </button>
            <b-tooltip target="create-button">
              {{
                canUpdate === true
                  ? $t('New Connection')
                  : $t('You dont have permission to add connection')
              }}
            </b-tooltip>
          </div>
          <div
            v-for="item of items"
            :key="item.vueKey"
            class="prozess-connections__item"
          >
            <div
              class="prozess-connections__summary d-flex justify-content-center align-items-center"
            >
              <div class="prozess-connections__item-name">
                <prozess-resource-name
                  :has-permission="$can('Read', getPermissionName(item))"
                  :to="{
                    name: `${item.entityType}-view`,
                    params: {
                      id: item.uuid,
                      tab: getDefaultTab(item.entityType)
                    },
                  }"
                  :title="item.name"
                />
              </div>
              <div
                class="prozess-connections__item-right-buttons d-flex align-items-center"
              >
                <div class="prozess-connections__item-action-buttons d-flex">
                  <div style="width: 200px">
                    <b-badge
                      v-if="item.entityType"
                      variant="outline-primary"
                    >
                      {{ $t($case.title(item.entityType)) }}
                    </b-badge>
                  </div>
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`delete-row-${item.uuid}-preview-icon`"
                      icon="TrashIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      :class="{
                        'icon-disabled': canUpdate === false,
                      }"
                      @click="remove(item)"
                    />
                    <b-tooltip
                      :title="
                        canUpdate === true
                          ? $t('Remove')
                          : $t('You don\'t have permission to remove this connection.')
                      "
                      :target="`delete-row-${item.uuid}-preview-icon`"
                      placement="left"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { getDefaultTab, swalConfirmDeleteOption } from '@/helpers/app'
import documentService from '@/services/document'
import noteService from '@/services/note'
import ConnectionForm from '@/views/shared/Connections/Form.vue'
import { Schema } from '@/constants/app'

export default {
  components: {
    ConnectionForm,
  },

  props: {
    resource: {
      type: Object,
      required: true,
    },

    entityType: {
      type: String,
      default: 'document',
    },

    versionKey: {
      type: String,
      default: 'documentVersion',
    },

    connectionsKey: {
      type: String,
      default: 'connectedEntitySingleResponse',
    },
  },

  data() {
    return {
      resourceId: null,
      companyId: null,
      showForm: false,
      loading: false,
      items: [],
      customFields: [],
    }
  },

  computed: {
    canUpdate() {
      switch (this.entityType) {
        case 'note':
          return this.$can('Update', 'Note')

        default:
          return this.$can('Update', 'Document')
      }
    },

    service() {
      switch (this.entityType) {
        case 'note':
          return noteService

        default:
          return documentService
      }
    },
  },

  watch: {
    resource: {
      handler(value) {
        this.items = value[this.connectionsKey]
      },

      immediate: true,
    },
  },

  methods: {
    getDefaultTab,

    getPermissionName(item) {
      const permission = {
        [Schema.CORE]: this.$case.title(item.entityType),
        [Schema.CRM]: Schema.CRM.toUpperCase(),
      }
      return permission[item.schemaName] || item.entityType
    },

    edit(item) {
      this.resourceId = item.relationshipId
      this.companyId = item.uuid
      this.showForm = true
    },

    collapse(item) {
      this.$set(item, 'visible', !item.visible)
    },

    create() {
      this.showForm = !this.showForm
    },

    onCloseForm() {
      this.resourceId = null
      this.companyId = null
      this.showForm = false
    },

    remove(item) {
      if (this.canUpdate === false) return
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.service
            .detach(this.resource.uuid, {
              // Structure in documents
              schemaName: item.schemaName,
              tableName: item.entityType,

              entityType: item.entityType,
              entityId: item.uuid,
              [this.versionKey]: this.resource[this.versionKey],
            })
            .then(() => {
              this.$emit('update')
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/entity-connections.scss';
</style>
