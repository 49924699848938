import * as yup from 'yup'
import { customFieldsSchema } from '@/schema/customFields'
import { shapeSchemaByMetadata } from '@/helpers/field'

export const entityDocumentSchema = customFields => yup.object().shape(
  {
    name: yup
      .string()
      .required()
      .max(100, 'Max 100 limit'),
    customFields: customFieldsSchema(customFields),
  },
)

export const documentMetadataSchema = shapeSchemaByMetadata

export default {
  entityDocumentSchema,
  documentMetadataSchema,
}
