<template>
  <b-modal
    :id="documentPreviewId"
    v-model="isModalOpen"
    class="prozess-document-preview-modal"
    hide-footer
    centered
    no-fade
    no-close-on-backdrop
    size="lg"
    :title="$lodash.get(activeDocumentPreview, 'name', '')"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="height: 100%;"
      class="d-flex flex-column"
    >
      <div v-if="activeDocumentPreview">
        <div v-if="directUrl">
          <div style="position: absolute; top: 40%; left: -100px">
            <b-button
              variant="outline-secondary"
              class="btn-icon rounded-circle d-flex align-items-center"
              style="height: 50px; width: 50px;"
              :disabled="!isSwitchEnabled('prev')"
              @click="switchPreview('prev')"
            >
              <feather-icon
                style="margin-left: 3px;"
                icon="ChevronLeftIcon"
                size="25"
              />
            </b-button>
          </div>
          <div style="position: absolute; top: 40%; right: -100px">
            <b-button
              variant="outline-secondary"
              :disabled="!isSwitchEnabled('next')"
              class="btn-icon rounded-circle d-flex align-items-center"
              style="height: 50px; width: 50px;"
              @click="switchPreview('next')"
            >
              <feather-icon
                style="margin-left: 5px;"
                icon="ChevronRightIcon"
                size="25"
              />
            </b-button>
          </div>
          <object
            v-if="directUrl.mimeType === 'application/pdf'"
            ref="attachmentElement"
            style="width: 100%; height: 100vh"
            :data="directUrl.url"
            type="application/pdf"
          />
          <div v-else>
            <p class="text-center my-4">
              {{ $t('No preview available') }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        style="height: 100px;"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import documentAttachmentService from '@/services/documentAttachment'

export default {
  props: {
    activeDocumentPreview: {
      type: Object,
      default: null,
    },
    entityType: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
    schemaName: {
      type: String,
      default: 'crm',
    },
  },
  data() {
    return {
      isModalOpen: false,
      loading: true,
      documentPreviewId: 'documentPreview',
      directUrl: null,
      document: null,
      versions: [],
    }
  },
  watch: {
    isModalOpen(value) {
      if (value === false) {
        this.reset()
      }
    },
    activeDocumentPreview() {
      this.getDocumentDetails()
    },
  },
  methods: {
    reset() {
      if (this.versions.length) {
        const [first] = this.versions
        this.fetchDirectUrl(first.id)
        this.versions.forEach((item, index) => {
          this.$set(this.versions, index, {
            ...this.versions[index],
            active: index === 0,
          })
        })
      }
    },
    fetchDirectUrl(versionId) {
      documentAttachmentService.directUrl({
        schemaName: this.schemaName,
        entityType: this.entityType,
        entityId: this.entityId,
        fileUuid: this.document.uuid,
        query: {
          versionId,
        },
      }).then(res => {
        this.directUrl = res.data
      })
    },
    isSwitchEnabled(choice) {
      const activeIndex = this.versions.findIndex(item => item.active)
      if (choice === 'next') {
        return activeIndex + 1 !== this.versions.length
      }
      if (choice === 'prev') {
        return activeIndex > 0
      }
      return false
    },
    switchPreview(choice) {
      const getFileUuid = () => {
        const activeIndex = this.versions.findIndex(item => item.active)
        this.$set(this.versions, activeIndex, {
          ...this.versions[activeIndex],
          active: false,
        })
        const selectedIndex = choice === 'next' ? activeIndex + 1 : activeIndex - 1
        const result = this.versions[selectedIndex]
        if (result) {
          this.$set(this.versions, selectedIndex, {
            ...this.versions[selectedIndex],
            active: true,
          })
        }
        return result ? result.id : null
      }

      const versionId = getFileUuid()

      if (versionId) {
        this.fetchDirectUrl(versionId)
      }
    },
    getDocumentDetails() {
      this.directUrl = null
      this.loading = true
      documentAttachmentService.getOne(this.schemaName, this.entityType, this.entityId, this.activeDocumentPreview.uuid)
        .then(res => {
          this.document = res.data
          this.versions = this.document.versions
          if (this.versions.length) {
            this.$set(this.versions, 0, {
              ...this.versions[0],
              active: true,
            })
          }
          this.directUrl = res.data.directUrlLatestVersion
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
