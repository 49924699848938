var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('connection-form',{attrs:{"version-key":_vm.versionKey,"service":_vm.service,"version":_vm.resource[_vm.versionKey],"visible":_vm.showForm,"resource-id":_vm.resourceId,"company-id":_vm.companyId},on:{"saved":function($event){return _vm.$emit('update')},"close":_vm.onCloseForm}}),_c('b-overlay',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"show":_vm.loading,"spinner-variant":"primary","rounded":"sm","variant":"transparent"}},[_c('div',{staticClass:"card resource-view__scrollable"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-2"},[_c('button',{staticClass:"btn btn-primary d-flex justify-content-around align-items-center btn-secondary",class:{
              'icon-disabled': _vm.canUpdate === false,
            },attrs:{"id":"create-button"},on:{"click":function($event){_vm.canUpdate && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserPlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('New Connection')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.canUpdate === true ? _vm.$t('New Connection') : _vm.$t('You dont have permission to add connection'))+" ")])],1),_vm._l((_vm.items),function(item){return _c('div',{key:item.vueKey,staticClass:"prozess-connections__item"},[_c('div',{staticClass:"prozess-connections__summary d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"prozess-connections__item-name"},[_c('prozess-resource-name',{attrs:{"has-permission":_vm.$can('Read', _vm.getPermissionName(item)),"to":{
                  name: `${item.entityType}-view`,
                  params: {
                    id: item.uuid,
                    tab: _vm.getDefaultTab(item.entityType)
                  },
                },"title":item.name}})],1),_c('div',{staticClass:"prozess-connections__item-right-buttons d-flex align-items-center"},[_c('div',{staticClass:"prozess-connections__item-action-buttons d-flex"},[_c('div',{staticStyle:{"width":"200px"}},[(item.entityType)?_c('b-badge',{attrs:{"variant":"outline-primary"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$case.title(item.entityType)))+" ")]):_vm._e()],1),_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                      'icon-disabled': _vm.canUpdate === false,
                    },attrs:{"id":`delete-row-${item.uuid}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.remove(item)}}}),_c('b-tooltip',{attrs:{"title":_vm.canUpdate === true
                        ? _vm.$t('Remove')
                        : _vm.$t('You don\'t have permission to remove this connection.'),"target":`delete-row-${item.uuid}-preview-icon`,"placement":"left"}})],1)])])])])})],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }