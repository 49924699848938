<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <template v-if="resourceId">
        <prozess-input
          id="documentId"
          v-model="form.documentId"
          :placeholder="$t('Document ID')"
          field="documentId"
          name="documentId"
          disabled
        />
        <prozess-input
          id="createdDate"
          v-model="form.createdDate"
          :placeholder="$t('Date Time')"
          field="createdDate"
          name="createdDate"
          disabled
        />
        <prozess-input
          id="createdByUserName"
          v-model="form.createdByUserName"
          :placeholder="$t('Created By')"
          field="createdByUserName"
          name="createdByUserName"
          disabled
        />
      </template>
      <div
        v-for="field in fieldMetadata"
        :key="field.key"
      >
        <AppDynamicField
          v-model="form[field.key]"
          :field="field"
          :error="$hasError(field.key)"
          :resourceId="resourceId"
        />
      </div>
      <prozess-field-wrapper class="flex mt-1 tw-pt-3">
        <prozess-select
          v-model="form.availableTo"
          :placeholder="$t('Available To')"
          :options="availableToOptions"
          :clearable="false"
          class="w-100"
          :get-option-label="option => option.name"
        />
      </prozess-field-wrapper>
      <prozess-file-upload
        v-if="!resourceId"
        v-model="file"
        :max-file-size="10"
        :allowed-mime-types="allowedMimeTypes"
      />
      <div v-else>
        <prozess-input
          v-model="fileName"
          :placeholder="$t('File Name')"
          icon="FileIcon"
          field="fileName"
          name="fileName"
          disabled
          @enter="save"
        />
        <input
          ref="newFileInput"
          type="file"
          @change="handleNewFileInputChange"
        />
        <div class="d-flex align-items-center row-flex-justify-space-between">
          <b-button
            variant="secondary"
            style="height: 34px"
            @click="replaceFile"
          >
            {{ $t('Replace') }}
          </b-button>
          <b-button
            variant="outline-primary"
            :disabled="!canDelete"
            @click="remove()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            {{ $t((entityType ? 'Detach' : 'Delete') + ' Document') }}
          </b-button>
          <!-- <button
            class="btn btn-outline-primary d-flex align-items-center"
            type="button"
            :disabled="!canDelete"
            @click="remove()"
          >
            <feather-icon icon="TrashIcon" class="mr-50 tw-text-white" />
            <span class="tw-text-white">
              {{ $t((entityType ? 'Detach' : 'Delete') + ' Document') }}
            </span>
          </button> -->
        </div>
      </div>
      <span
        v-if="$hasError('file')"
        class="invalid-feedback"
      >
        {{ $t($hasError('file')) }}
      </span>
      <form-notification
        v-if="resourceId"
        :id="resourceId"
      />
      <div
        v-if="!resourceId"
        class="my-1"
      >
        <span class="text-muted">
          {{ $t('Add connection for contact/company.') }}
        </span>

        <entity-search
          v-model="connections.contact"
          entity-type="contact"
          place-holder="Contact"
          class="my-1"
          :hint="$t('Search terms must be at least 3 characters in length')"
        />

        <entity-search
          v-model="connections.company"
          entity-type="company"
          place-holder="Company"
          :hint="$t('Search terms must be at least 3 characters in length')"
        />
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { preventSpecialChars } from '@/helpers/app'
import { entityDocumentSchema, documentMetadataSchema } from '@/schema/document'
import { fetchBackendErrors } from '@/helpers/backendValidations'
import FormNotification from '@/components/Forms/FormNotification.vue'
import ProzessInput from '@core/components/ProzessInput.vue'
import documentService from '@/services/document'
import documentAttachmentService from '@/services/documentAttachment'
import advancedFieldMgmt from '@/services/advancedFieldMgmt'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import fieldMixins, { customFieldsMixins } from '@/mixins/fields'
import { fillableOnly } from '@/helpers/field'
import EntitySearch from '@/views/shared/Connections/EntitySearch.vue'
import dayjs from 'dayjs'
import groupService from '@/services/group'
import employeeService from '@/services/employee'
import { mapState } from 'vuex'
import moment from 'moment'

const staticField = {
  documentId: 'documentId',
  createdDate: 'createdDate',
  createdByUserName: 'createdByUserName',
}

export default {
  name: 'DocumentForm',
  components: {
    ProzessInput,
    FormNotification,
    AppDynamicField,
    EntitySearch,
  },
  mixins: [fieldMixins, customFieldsMixins],

  props: {
    entityType: {
      type: String,
      default: null,
    },

    entityId: {
      type: String,
      default: null,
    },

    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },

    schemaName: {
      type: String,
      default: 'crm',
    },
  },

  data() {
    return {
      options: [],
      saving: false,
      loading: true,
      errors: [],
      fieldMetadata: [],
      version: '',
      file: null,
      serviceName: documentService,
      fileName: '',
      allowedMimeTypes: [],
      formId: 'documentForm',
      form: {},
      resource: {},
      availableToOptions: [],
      currentAvailableTo: '',
    }
  },

  computed: {
    ...mapState('user', ['user']),
    formTitle() {
      return this.resourceId ? 'Edit Document' : 'Add New Document'
    },

    canDelete() {
      switch (this.entityType) {
        case 'contact':
        case 'company':
          return this.$can('Update', 'CRM')

        case 'note':
          return this.$can('Update', 'Note')

        case 'inquiry':
          return this.$can('Update', 'Inquiry')

        default:
          return this.$can('Delete', 'Document')
      }
    },
  },

  watch: {
    file(value) {
      if (!this.form.name && value) {
        const filename = value.name.split('.')
        if (filename.length > 1) {
          filename.pop()
        }
        this.form.name = filename.join('.')
      }
    },

    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          this.getInitialData()
        }
      },

      immediate: true,
    },
  },

  methods: {
    handleNewFileInputChange(e) {
      const maxFileSize = 10
      const [file] = e.target.files

      const validations = [
        {
          condition: maxFileSize < file.size / 1024 / 1024,
          message: `${this.$t('File should not exceed')} ${maxFileSize}mb`,
        },
        {
          condition: this.allowedMimeTypes.length && !this.allowedMimeTypes.includes(file.type),
          message: this.$t('File type not supported'),
        },
      ]

      let containsError = false

      validations.forEach(item => {
        if (containsError) return

        if (item.condition) {
          containsError = true
          this.$refs.newFileInput.value = ''

          this.currentValue = null
          return this.$swal({
            title: item.message,
            icon: 'warning',
            confirmButtonText: this.$t('Close'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
            allowOutsideClick: false,
          })
        }
      })

      if (containsError) return

      this.fileName = file.name
      this.file = file
    },

    replaceFile() {
      this.$refs.newFileInput.click()
    },

    remove() {
      if (!this.canDelete) return

      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to delete?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const removeOrDetach = () =>
            this.entityType
              ? documentAttachmentService.detach(this.schemaName, this.entityType, this.entityId, {
                  documentUuid: this.resourceId,
                  documentVersion: this.version,
                })
              : documentService.destroy(this.resourceId, {
                  version: this.version,
                })

          removeOrDetach().then(() => {
            if (this.$route.name === 'document-view') {
              return this.$router.push({ name: 'documents' })
            }
            this.close()
            this.$emit('saved')
          })
        }
      })
    },

    handleFile(e) {
      const [file] = e.target.files
      this.file = file
    },

    async getInitialData() {
      this.loading = true

      const res = await Promise.all([
        advancedFieldMgmt.getFieldsByEntity({
          schema: 'documents',
          table: 'document',
        }),
        groupService.getAll(),
      ])
      const groups = res[1].data.pageItems
      const loggedInUser = {
        uuidKey: this.user.userUuid,
        name: this.$t('Private'),
        type: 'private',
      }
      const availableToAll = {
        uuidKey: null,
        name: this.$t('Public'),
        type: 'public',
      }
      const newGroups = groups.map(v => ({ ...v, type: 'group' }))
      this.availableToOptions = [loggedInUser, availableToAll, ...newGroups]
      this.form.availableTo = loggedInUser
      const fieldMetadata = fillableOnly(res[0].data)
      const standardFields = Object.keys(staticField).map(key => ({ key }))
      const standard = fieldMetadata.filter(i => i.id == null)
      const custom = fieldMetadata.filter(i => i.id != null)
      const fieldsCombine = [...fieldMetadata, ...standardFields]
      fieldsCombine.forEach(field => {
        if (field.type === 'BOOL') {
          this.$set(this.form, field.key, false)
        } else this.$set(this.form, field.key, null)
      })

      this.fieldMetadata = this.$lodash.cloneDeep([...custom, ...standard])
      if (this.resourceId) await this.$async(this.fetchData())

      this.loading = false
    },

    async fetchData() {
      const { response } = await this.$async(this.serviceName.getOne(this.resourceId))
      this.version = response.data.documentVersion
      this.formatAsFormData(response.data, 'customFieldData')
      this.form.documentId = response.data.documentId
      this.form.createdDate = response.data.createdDate ? dayjs(response.data.createdDate).format('MMMM DD, YYYY HH:mm') : ''
      this.form.createdByUserName = response.data.createdByUserName
      const { availableToGroupUuid, availableToUserUuid, availableToPublic } = response.data
      if (availableToGroupUuid) {
        this.form.availableTo = this.availableToOptions.filter(({ uuidKey }) => uuidKey === availableToGroupUuid)
        this.currentAvailableTo = this.form.availableTo
      }
      if (availableToUserUuid) {
        this.form.availableTo = this.availableToOptions.filter(({ uuidKey }) => uuidKey === availableToUserUuid)
        this.currentAvailableTo = this.form.availableTo
      }
      if (availableToPublic) {
        this.form.availableTo = this.availableToOptions.filter(({ type }) => type === 'public')
        this.currentAvailableTo = this.form.availableTo
      }
      this.fileName = response.data.fileName
    },

    preventSpecialChars,

    handleGroupInput() {
      this.$forceUpdate()
    },

    reset() {
      this.errors = []
      this.form = {}
      this.fileName = null
      this.customFields = []
      this.loading = false
      this.saving = false
      this.file = null
      this.connections = []
    },

    close() {
      this.$emit('close')
    },

    changeFile() {
      return documentService.updateWithNewFile(this.resourceId, this.prepareFile('newVersionForm')).catch(() => {
        this.fileName = this.form.fileName
        this.file = null
      })
    },

    async save() {
      const staticFieldValidations = [
        {
          required: true,
          key: 'file',
          type: 'TEXT',
        },
      ]

      this.errors = await this.yupValidate(documentMetadataSchema([...this.fieldMetadata, ...staticFieldValidations]), {
        ...this.form,
        file: this.file?.name ?? this.fileName,
      })

      if (!this.errors.length) {
        this.saving = true
        const removeCurrentAvailability = {
          ...(this.currentAvailableTo[0]?.type === 'public'),
          ...(this.currentAvailableTo[0]?.type === 'group' && { removeAssignedGroupUuid: this.currentAvailableTo[0]?.uuidKey }),
          ...(this.currentAvailableTo[0]?.type === 'private' && { removeAssignedUserUuid: this.currentAvailableTo[0]?.uuidKey }),
        }
        const payload = {
          documentVersion: this.resourceId ? this.version : undefined,
          isPublic: this.form.availableTo.type === 'public',
          ...(this.form.availableTo.type === 'group' && { groupUuid: this.form.availableTo.uuidKey }),
          ...(this.form.availableTo.type === 'private' && { userUuid: this.form.availableTo.uuidKey }),
          ...this.formatAsPayload(),
          ...(this.currentAvailableTo !== this.form.availableTo && { ...removeCurrentAvailability }),
        }
        Object.values(this.fieldMetadata).filter(cf => cf.dateAutoset && cf.customField).forEach(cc => {
          const dateNow = new Date()
          if (cc.type === 'DATE') {
            payload.customFields[cc.key] = moment(dateNow).format('YYYY-MM-DD')
          } else if (cc.type === 'DATETIME') {
            payload.customFields[cc.key] = moment(dateNow).format('YYYY-MM-DDTHH:mmZ')
          }
        })
        const response = await this.$async(this.createOrUpdate(payload))

        if (response.errors || response.error) {
          const isMaxLength = fetchBackendErrors(response.error, this.resourceId)

          this.saving = false
          return this.showWarning(!isMaxLength ? this.$t("Oops! Something went wrong on our end. We'll fix this as soon as possible.") : isMaxLength)
        }
        this.$emit('saved')
        this.close()
      }
    },

    getEntityId(data) {
      return this.$lodash.get(data, 'value.uuid')
    },

    setRealationshipPayload() {
      let attachments = []
      if (this.connections.contact) {
        attachments = [
          ...attachments,
          {
            schemaName: 'crm',
            tableName: 'contact',
            entityId: this.getEntityId(this.connections.contact),
          },
        ]
      }

      if (this.connections.company) {
        attachments = [
          ...attachments,
          {
            schemaName: 'crm',
            tableName: 'company',
            entityId: this.getEntityId(this.connections.company),
          },
        ]
      }
      return attachments
    },

    prepareFile(data) {
      const formName = 'form'
      const formData = new FormData()
      formData.append(
        formName,
        new Blob([JSON.stringify(data)], {
          type: 'application/json',
        }),
      )
      if (this.file) {
        formData.append(
          'form',
          new Blob([JSON.stringify({ name: this.file.name })], {
            type: 'application/json',
          }),
        )

        formData.append('file', this.file)
      }
      if (this.connections) {
        formData.append(
          'formEntities',
          new Blob([JSON.stringify(this.setRealationshipPayload())], {
            type: 'application/json',
          }),
        )
      }

      return formData
    },

    createOrUpdate(data) {
      if (this.entityType) {
        return this.resourceId
          ? documentAttachmentService.patch(this.schemaName, this.entityType, this.entityId, this.resourceId, data)
          : documentAttachmentService.store(this.schemaName, this.entityType, this.entityId, this.prepareFile(data))
      }

      if (!this.resourceId && this.resource) {
        return documentAttachmentService.storeWithAttachment(this.prepareFile(data))
      }

      return this.resourceId ? documentService.patch(this.resourceId, data) : documentService.store(this.prepareFile(data))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/form-sidebar.scss';
input[type='file'] {
  display: none;
}
</style>
