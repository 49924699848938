<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    :save-icon="saveIcon"
    :save-label="saveLabel"
    @close="close"
    @submit="save"
  >
    <form
      :id="form"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-field-wrapper
        icon="MenuIcon"
        :error="$hasError('entityType')"
      >
        <prozess-select
          v-model="resource.entityType"
          style="flex: 1"
          :placeholder="$t('Connection Type')"
          :options="connectionTypesWithLabel"
          label="label"
          :reduce="type => type.value"
        />
      </prozess-field-wrapper>
      <entity-search
        v-model="resource.entity"
        :entity-type="resource.entityType"
        :error="$hasError('entityId')"
        :hint="$t('Search terms must be at least 3 characters in length')"
      />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import documentService from '@/services/document'
import { connectionSchema } from '@/schema/connection'
import EntitySearch from '@/views/shared/Connections/EntitySearch.vue'

export default {
  components: {
    EntitySearch,
  },

  props: {
    version: {
      type: Number,
      default: null,
    },

    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },

    companyId: {
      type: [String, Number],
      default: null,
    },

    versionKey: {
      type: String,
      default: 'documentVersion',
    },

    service: {
      type: Object,
      default: () => documentService,
    },
  },

  data() {
    return {
      entity: null,
      form: 'connectionForm',
      resource: {},
      saving: false,
      loading: true,
      errors: [],
      connectionTypes: ['contact', 'company'],
    }
  },

  computed: {
    connectionTypesWithLabel() {
      return this.connectionTypes.map(type => ({
        label: this.$t(this.$case.title(type)),
        value: type,
      }))
    },

    saveIcon() {
      return this.resourceId ? '' : 'PlusCircleIcon'
    },

    saveLabel() {
      return this.resourceId ? 'Save Changes' : 'Add Connection'
    },

    formTitle() {
      return this.resourceId ? 'Edit Connection' : 'Add Connection'
    },
  },

  watch: {
    'resource.entityType': function (value) {
      this.resource.entity = null
      this.resource.entityId = null
    },

    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
        }
      },
      immediate: true,
    },
  },

  methods: {
    reset() {
      this.errors = []
      this.resource = {
        entityId: null,
        entity: null,
      }
      this.loading = false
      this.saving = false
    },

    close() {
      this.reset()
      this.$emit('close')
    },

    async save() {
      this.resource.entityId = this.$lodash.get(
        this.resource,
        'entity.value.uuid',
      )

      this.errors = await this.yupValidate(connectionSchema, this.resource)
      if (this.errors.length > 0) return

      this.saving = true

      this.service
        .attach(this.$route.params.id, {
          ...this.resource,
          [this.versionKey]: this.version,
        })
        .then(res => {
          this.$emit('saved')
          this.close()
        })
        .catch(err => {
          const errors = this.$lodash.get(err, 'response.data', [])
          this.errors = this.$lodash.invoke(errors, 'map', error => {
            if (error.objectName === 'form.customFields') {
              error.field = `customFields.${error.field}`
            }

            return error
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/form-sidebar.scss';
</style>
