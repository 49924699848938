import * as yup from 'yup'

import CustomFieldTypes from '@/constants/customFieldTypes'

export const fieldValidations = {
  TEXT: yup.string().nullable(),
  LONGTEXT: yup.string().nullable(),
  PHONE: yup.string().nullable(),
  URL: yup.string()
    .nullable()
    .matches(
      /((https?):\/\/)?(www.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
      'Must be a valid URL',
    ),
  EMAIL: yup.string().email().nullable(),
  NUMBER: yup.number().nullable().transform(v => (isNaN(v) ? null : v)).typeError('Required'),
  PERCENT: yup.number().nullable().typeError('Must be a number'),
  DATE: yup.date().nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
  LIST: yup.string().nullable().transform(v => (v === null ? '' : v)),
  MULTISELECT: yup.mixed(val => (Array.isArray(val) ? yup.array() : yup.string())).nullable(),
  BOOL: yup.boolean().nullable(),
  QUERY: yup.string().nullable(),
  DATETIME: yup.string().nullable(),
}

export function isFieldTypeNumeric(type) {
  switch (type) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Percent:
      return true

    default:
      return false
  }
}

export function isFieldTypeText(type) {
  switch (type) {
    case CustomFieldTypes.Email:
    case CustomFieldTypes.Phone:
    case CustomFieldTypes.Text:
    case CustomFieldTypes.LongText:
    case CustomFieldTypes.Url:
    case CustomFieldTypes.MultiSelect:
      return true

    default:
      return false
  }
}

export const makeSchema = field => {
  const {
    required, maxCharacterCount, minCharacterCount, minValue, maxValue,
  } = field

  let validations = fieldValidations[field.type]
  if (required) {
    validations = validations.required('Required')
  }

  if (isFieldTypeText(field.type)) {
    if (minCharacterCount || minCharacterCount === 0) {
      validations = validations.min(minCharacterCount, `Min limit ${minCharacterCount}`)
    }

    if (maxCharacterCount || maxCharacterCount === 0) {
      validations = validations.max(maxCharacterCount, `Max limit ${maxCharacterCount}`)
    }
  }

  if (isFieldTypeNumeric(field.type)) {
    if (minValue || minValue === 0) {
      validations = validations.min(minValue, `Min value ${minValue}`)
    }

    if (maxValue || maxValue === 0) {
      validations = validations.max(maxValue, `Max value ${maxValue}`)
    }
  }

  return validations
}

// Generate an entire yup schema object by providing an AFM Metadata
export const makeSchemaByMetadata = metadata => metadata.reduce((acc, item) => {
  acc[item.key] = makeSchema(item)
  return acc
}, {})

// Shape an entire yup schema object by providing an AFM Metadata
export const shapeSchemaByMetadata = fieldMetadata => {
  const schema = makeSchemaByMetadata(fieldMetadata)
  return yup.object().shape(schema)
}

// Remove Hidden and Query fields
export const fillableOnly = (meta, options = {}) => meta.filter(
  item => {
    let condition = options.condition ?? (!item.hidden && item.type !== CustomFieldTypes.Query)

    if (options.additionalCondition) {
      condition = condition && options.additionalCondition(item)
    }

    return item.required || condition
  },
)
