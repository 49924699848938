<template>
  <div>
    <sidebar-form
      :visible="showForm"
      :resource-id="resourceId"
      @saved="getDetails()"
      @close="onCloseForm"
    />
    <prozess-resource-view
      :resource="resource"
      :loading="loading"
      :fields="fields"
      :custom-fields="customFields"
      :can-update="$can('Update', 'Note')"
      @edit="$can('Update', 'Note') ? edit(resource.uuid) : null"
    >
      <template slot="body">
        <b-tabs
          content-class="mt-1"
          :value="tabs.indexOf(this.$route.params.tab)"
          @input="handleTabInput"
        >
          <b-tab>
            <template #title>
              <feather-icon icon="GridIcon" /> {{ $t('360 Overview') }}
            </template>
            <EntityOverviewWidgets
              :resource="resource"
              :field-labels="fields"
              schema-key="notes"
              table-key="note"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="CheckSquareIcon" /> {{ $t('Documents') }}
            </template>
            <document-list
              entity-type="note"
              schema-name="notes"
              :with-edit-action="false"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="GitMergeIcon" /> {{ $t('Connections') }}
            </template>
            <entity-connections
              :resource="resource"
              version-key="noteVersion"
              connections-key="connectedEntities"
              entity-type="note"
              @update="getDetails()"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon" /> {{ $t('History') }}
            </template>
            <b-overlay
              :show="loading"
              spinner-variant="primary"
              rounded="sm"
              variant="transparent"
              style="height: 100%;"
              class="d-flex flex-column"
            >
              <div class="card">
                <div class="card-body mb-5">
                  <prozess-resource-history
                    :history="items"
                    :loading="loading"
                    :history-pagination="{
                      currentPage,
                      total,
                      perPage,
                    }"
                    @handleCurrentPage="handleCurrentPage"
                  />
                </div>
              </div>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </template>
    </prozess-resource-view>
  </div>
</template>

<script>
import { GenericTabs } from '@/constants/app'
import noteService from '@/services/note'
import listMixinFactory from '@/mixins/list'
import SidebarForm from '@/views/pages/Notes/Form.vue'
import DocumentList from '@/views/pages/Documents/ListOnView/index.vue'
import EntityConnections from '@/views/shared/Connections/ListOnView.vue'
import RootEvents from '@/constants/rootEvents'
import EntityOverviewWidgets from '@/components/EntityOverview/EntityOverviewWidgets.vue'

export default {
  components: {
    SidebarForm,
    DocumentList,
    EntityConnections,
    EntityOverviewWidgets,
  },

  mixins: [
    listMixinFactory({
      service: noteService,
      isHistory: true,
    }),
  ],

  data() {
    return {
      loading: true,

      resource: {
        customFields: {},
      },

      fields: {
        title: 'title',
        note: 'note',
      },

      customFields: {},
      tabs: [
        GenericTabs.OVERVIEW_360,
        GenericTabs.DOCUMENTS,
        GenericTabs.CONNECTIONS,
        GenericTabs.HISTORY,
      ],
    }
  },

  mounted() {
    this.getDetails()
  },

  methods: {
    handleTabInput(index) {
      this.$router
        .push({
          name: 'note-view',
          params: {
            id: this.$route.params.id,
            tab: this.tabs[index] || null,
          },
        })
        .catch(() => {})
    },

    getDetails() {
      return noteService.getOne(this.$route.params.id).then(res => {
        const sortedCustomFields = this.$customFields.sort(
          res.data.customFieldMetadataSingleResponses,
        )

        this.customFields = sortedCustomFields.reduce((acc, field) => {
          acc[field.key] = field
          return acc
        }, {})

        this.resource = {
          ...res.data,
          customFields: this.$customFields.formatForResource(
            res.data.customFieldMetadataSingleResponses,
            res.data.customFieldDataResponses,
          ),
        }

        this.$root.$emit(RootEvents.SET_PAGE_TITLE, this.resource.title)
      })
    },
  },
}
</script>
