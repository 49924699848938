import API from '@axios'
import { stringify as toQuery } from 'query-string'

const API_URL = '/api/documents/attachment'

export default {
  getAll: (schemaName, entityType, entityId, query = { page: 0 }) => API.get(`${API_URL}/${schemaName}/${entityType}/${entityId}?${toQuery(query)}`),
  getOne: (schemaName, entityType, entityId, id, query) => API.get(`${API_URL}/${schemaName}/${entityType}/${entityId}/${id}?&${toQuery(query)}`),
  store: (schemaName, entityType, entityId, payload) => API.post(`${API_URL}/${schemaName}/${entityType}/${entityId}`, payload),
  update: (schemaName, entityType, entityId, id, payload) => API.put(`${API_URL}/${schemaName}/${entityType}/${entityId}/${id}`, payload),
  patch: (schemaName, entityType, entityId, id, payload) => API.patch(`${API_URL}/${schemaName}/${entityType}/${entityId}/${id}`, payload),
  destroy: (schemaName, entityType, entityId, id, query) => API.delete(`${API_URL}/${schemaName}/${entityType}/${entityId}/${id}?${toQuery(query)}`),
  detach: (schemaName, entityType, entityId, payload) => API.put(`${API_URL}/${schemaName}/${entityType}/${entityId}/detach`, payload),
  detachOrDelete: (type, {
    schemaName, entityType, entityId, documentUuid, documentVersion,
  }) => {
    if (type === 'detach') {
      return API.put(`${API_URL}/${schemaName}/${entityType}/${entityId}/detach`, {
        documentUuid, documentVersion,
      })
    }
    if (type === 'delete') {
      return API.delete(`${API_URL}/${schemaName}/${entityType}/${entityId}/${documentUuid}?${toQuery({
        version: documentVersion,
      })}`)
    }
    return Promise.reject()
  },
  getCustomFields: (schemaName, entityType) => API.get(`${API_URL}/${schemaName}/${entityType}/custom_fields`),
  directUrl: ({
    schemaName,
    entityType,
    entityId,
    fileUuid,
    query = {
      versionId: '',
    },
  }) => API.get(`${API_URL}/${schemaName}/${entityType}/${entityId}/${fileUuid}/direct_url?${toQuery(query)}`),
  storeWithAttachment: payload => API.post(`${API_URL}`, payload),
}
