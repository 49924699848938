<template>
  <b-card>
    <div class="tw-flex tw-items-center">
      <feather-icon
        class="text-secondary tw-mr-4 tw-shrink-0"
        :icon="widget.icon"
        size="18"
      />
      <p class="tw-inline-block m-0 line-clamp-1 tw-text-base tw-font-medium">
        {{ widget.name }}
      </p>
    </div>
    <div style="border-top: solid 1px gainsboro; margin-top: 12px; padding-top: 12px;">
      <EntityOverviewWidgetField
        v-for="(field, fieldIndex) in widget.fields"
        :key="fieldIndex"
        class="tw-mb-6"
        :parent-id="resource.uuid"
        :schema-key="schemaKey"
        :field="field"
        :standard-fields="resource"
        :custom-fields="resource.customFields"
        :centered="
          widget.fields.length === 1 &&
            field.fieldType !== CustomFieldTypes.LongText
        "
      />
    </div>
  </b-card>
</template>

<script>
import EntityOverviewWidgetField from '@/components/EntityOverview/EntityOverviewWidgetField.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'

export default {
  components: {
    EntityOverviewWidgetField,
  },

  props: {
    widget: {
      type: Object,
      required: true,
    },

    schemaKey: {
      type: String,
      required: true,
    },

    tableKey: {
      type: String,
      required: true,
    },

    resource: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      CustomFieldTypes,
    }
  },
}
</script>
